import React from "react";
import googlePlay from "../../Assets/googlePlay.svg";

const DownloadSection = () => {
  return (
    <div className="bg-[#D6F0EB] py-20">
      <h1 className="text-center mt-8 max-md:mt-2 heading text-4xl text-black_color max-lg:text-3xl max-md:text-xl max-sm:text-lg"><span className="text-green_color">Download</span> Quell <span className="text-green_color">Now</span> </h1>
      <img
                src={googlePlay}
                alt="google"
                className="mt-6 w-[300px] max-lg:w-[250px]  max-sm:w-[180px] mx-auto"
              />
    </div>
  );
};

export default DownloadSection;
