import React, { useRef, useEffect } from "react";
import success from "../../Assets/success.svg"
const Popup = (props) => {
  const popupRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [props.onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center !z-10">
      <div className="fixed inset-0 bg-black opacity-70"></div>

      <div
        ref={popupRef}
        className=" bg-white py-20 rounded-[9px] shadow-md min-w-[450px] w-[25%] !z-20"
      >
        <img src={success} alt="success" className="w-14 h-14 mx-auto"/>
        <div className="heading !font-medium text-base text-center mt-4 !leading-7">
        Thanks for joining the Quell waitlist! Stay tuned!
        </div>
      </div>
    </div>
  );
};

export default Popup;
