import React, { useState } from "react";

const FaqSection = () => {
  const faqData = [
    {
      question: "How does Quell help reduce doom scrolling? ",
      answer:
        "Quell uses a proprietary algorithm that interrupts doom scrolling sessions and guide users towards healthier screen habits.",
    },
    {
      question: "Can Quell really decrease my overall screen time? ",
      answer:
        "Yes, Quell is designed to gradually wean you off excessive screen time by promoting disciplined usage and providing tools for mindfulness.",
    },
    {
      question: "What makes Quell different from other screen-time management apps? ",
      answer:
        "Unlike other apps, Quell doesn't just block access to time-wasting apps. It employs a scientific approach to actively reduce digital addiction.",
    },
    {
      question: "How does Quell use neuroscience to combat digital addiction? ",
      answer:
        "Quell's methods are rooted in the latest research on addiction and understanding of the dopamine pathway. We use this knowledge to create a system that encourages healthy device use.",
    },
    {
      question: "Will Quell make it harder for me to use my phone? ",
      answer:
        "No, Quell aims to make your phone usage more intentional and beneficial, not to restrict it unnecessarily. It provides structured guidance while still offering usability.",
    },
    {
      question: "What is 'post-scroll clarity,' and how does Quell promote it?",
      answer:
        "Quell's breathing exercise is designed for quick anxiety relief post-scrolling, using a proven technique to calm the nervous system and restore clarity. Hence the term post-scroll clarity.",
    },
    {
      question: "Is Quell suitable for everyone? ",
      answer:
        'Quell is designed for anyone looking to improve their relationship with their digital devices, especially those who find themselves frequently lost in doom scrolling.',
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  return (
    <div id="faq" className="max-w-[1200px] px-[4%] mx-auto py-[5%] bg-[#F6FBFB] flex items-start max-md:flex-col">
      <div className="w-[40%] max-md:w-full">
        <h1 className="heading text-4xl !text-[#657489] text-left !font-medium max-w-[70%] max-lg:text-3xl max-md:text-2xl max-sm:text-lg ">
          FAQ
        </h1>
        <h1 className="heading text-4xl text-green_color text-left max-w-[70%] mt-5 max-lg:text-3xl max-md:text-2xl max-sm:text-lg">
          Our answers to your common questions
        </h1>
      </div>
      <div className="w-[60%] max-md:w-full max-md:mt-4">
        {faqData.map((faq, index) => (
          <div key={index} className={`${
            index == 2 && "rounded-b-md"
          } ${
            !index==0 && "border-t border-[#78777766]"
          }`}>
            <div
              onClick={() => toggleFAQ(index)}
              className={`text-normal text-left text-xl max-md:text-lg max-sm:text-base cursor-pointer flex justify-between items-center text-black_color bg-white px-6 py-5 ${
                index === 0 && "rounded-t-md"
              }   hover:bg-gray-300 transition duration-300`}
            >
              <span>{faq.question}</span>
              <svg
                className={`w-6 h-6 ${
                  expandedIndex === index ? "transform rotate-180" : ""
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    expandedIndex === index ? "M19 9l-7 7-7-7" : "M9 5l7 7 7-7"
                  }
                />
              </svg>
            </div>
            {expandedIndex === index && (
              <div
                className={`px-6 py-4 bg-white text-left text-black_color text-normal text-base max-sm:text-sm`}
              >
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
