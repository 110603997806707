import React from "react";

const StorySection = () => {
  return (
    <div id="story" className="py-[5%] px-[8%]">
      <div className=" max-w-[1200px] mx-auto ">
        <h1 className="heading text-3xl !font-normal text-center mx-auto w-[70%] max-lg:text-4xl max-md:text-2xl max-sm:text-lg max-sm:w-[90%]">
        From <span className="font-bold">Users</span> to <span className=" font-bold text-green_color">Innovators</span>
        </h1>
        <p className="bg-green_color px-4 py-3 font-bold w-fit mx-auto text-5xl text-white_color mt-5">The Quell Story</p>
        <div className="flex justify-between gap-10 mt-10 max-sm:flex-col">
          <p style={{ width: '30%' }} className="w-full text-normal text-lg !font-normal	text-black_color text-center max-md:text-lg max-sm:text-sm mx-auto border border-green_color rounded-[30px] px-5 py-10">
            We're not just creators but also users like you, 
            tired of solutions that don't solve our digital addictions.
          </p>
          <p style={{ width: '30%' }} className="w-full text-normal text-lg !font-normal	text-black_color text-center max-md:text-lg max-sm:text-sm mx-auto border border-green_color rounded-[30px] px-5 py-10">
            We investigated the science behind addiction and dopamine to develop a more effective solution.
          </p>
          <p style={{ width: '30%' }} className="w-full text-normal text-lg !font-normal !text-black_color text-center max-md:text-lg max-sm:text-sm mx-auto border border-green_color rounded-[30px] px-5 py-10">
            Our mission is to reshape tech use, turning phones into tools that enhance our lives and promote growth.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StorySection;
