import React from "react";
import Navbar from "../Navbar/Navbar";
import HeroContent from "../HeroContent/HeroContent";

const HeroSection = () => {
  return (
    <div className="bg-[#F3FAF9] py-[5vh]">
      <Navbar />
      <HeroContent />
    </div>
  );
};

export default HeroSection;
