import "./App.css";
import Home from "./Pages/Home/Home";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions/>,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy/>,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;