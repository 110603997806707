import React from "react";
import logoMain from "../../Assets/logoMain.svg";
const Navbar = () => {
  return (
    <div className="flex justify-between items-center px-[4%] max-w-[1200px] mx-auto ">
      <div className="bg-white rounded-[100px] px-12 py-3 shadow-sm max-sm:px-6 ">
        <img src={logoMain} alt="logo" className="w-[80px] max-sm:w-[50px]" />
      </div>
      <div className="bg-white rounded-[100px] px-10 py-5 shadow-sm flex gap-[5vw] max-sm:px-3 max-sm:gap-3  z-10">
        <a href="#work" className="text-normal text-black max-sm:text-xs">
          How it works
        </a>
        <a href="#story" className="text-normal text-black max-sm:text-xs">
          Our Story
        </a>
        <a href="#faq" className="text-normal text-black max-sm:text-xs">
          FAQ
        </a>
      </div>
    </div>
  );
};

export default Navbar;
