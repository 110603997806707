import React from "react";
import googlePlay from "../../Assets/googlePlay.svg";

const LostSection = () => {
  return (
    <div className="max-w-[1200px] px-[4%] mx-auto py-[5%]">
      <div className="py-10 bg-green_color rounded-[30px] hero-image2">
        <h1 className="heading text-5xl text-center text-white_color  max-lg:text-4xl max-md:text-3xl max-sm:text-2xl">
          Lost in the Scroll?
        </h1>
        <p className="text-normal text-white_color mt-5 w-[70%] text-center mx-auto max-sm:text-sm max-sm:w-[90%]">
          Quell puts you back in control of your phone! Our approach goes beyond
          simple app restrictions, gently decreasing your dependence to ensure
          every moment on your device serves a purpose.
        </p>
        <div className="mt-5 ">
          <p className="text-normal text-white_color text-3xl text-center !font-semibold max-md:text-lg max-sm:text-base">
            Download Now
          </p>
          <img
            src={googlePlay}
            alt="google"
            className="mt-2 w-[200px] max-lg:w-[120px] mx-auto max-sm:w-[80px]"
          />
        </div>
      </div>
    </div>
  );
};

export default LostSection;
