import React from "react";
import HeroSection from "../../Components/HeroSection/HeroSection";
import LostSection from "../../Components/LostSection/LostSection";
import StepsSection from "../../Components/StepsSection/StepsSection";
import StorySection from "../../Components/StorySection/StorySection";
import FaqSection from "../../Components/FaqSection/FaqSection";
import CourseSection from "../../Components/CourseSection/CourseSection";
import DownloadSection from "../../Components/DownloadSection/DownloadSection";
import Footer from "../../Components/Footer/Footer";
import bgMain from "../../Assets/bgDiamond.svg"
const Home = () => {
  return (
    <div>
      <HeroSection />
      <LostSection />
      <StepsSection />
      <DownloadSection/>
      <StorySection/>
      <FaqSection/>
      <CourseSection/>
      <DownloadSection/>
      <Footer/>
      <img src={bgMain} alt="bg" className="absolute top-10 left-[-100px] w-[50%]"/>
    </div>
  );
};

export default Home;
