import React, { useState } from "react";
import googlePlay from "../../Assets/googlePlay.svg";
import appStore from "../../Assets/appStore.svg";
import heroMobile from "../../Assets/hero-mobile.svg";
import heroShadow from "../../Assets/hero-shadow.svg";
import "../../firebase.js";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import Popup from "../Popup/Popup.jsx";

const HeroContent = () => {
  const db = getFirestore();
  const [email, setEmail] = useState("");
  const [popup, setPopup] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "email"), { email: email });
    setEmail("");
    setPopup(true);
  };

  return (
    <div className="max-w-[1200px] px-[4%] mx-auto py-[5%]  max-sm:py-14 z-20">
      <div className="w-[80%] mx-auto max-md:w-full">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="heading text-green_color text-left text-7xl max-lg:text-6xl max-md:text-5xl max-sm:text-3xl">
              Quell the Spell
            </h1>
            <p className="text-normal text-black_color text-2xl mt-5 max-lg:text-2xl text-left max-sm:text-base">
              <span className="font-semibold">Reclaim</span> your Life from Doom
              Scrolling
            </p>
            <div className="mt-10 justify-self-start	">
              <p className="text-normal text-left text-black_color text-xl !font-semibold max-md:text-lg max-sm:text-base">
                Download Now
              </p>
              <img
                src={googlePlay}
                alt="google"
                className="mt-2 w-[150px] max-lg:w-[120px]  max-sm:w-[80px]"
              />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img src={heroMobile} alt="hero-mobile" className="w-[70%]"/>
            <img src={heroShadow} alt="hero-shadow" className="mt-3 w-[80%] opacity-70"/>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-4 mt-[2%] max-sm:flex-col max-sm:mt-10">
        <p className="text-normal text-black_color text-xl max-md:text-lg max-sm:text-base">
          Coming soon to
        </p>
        <img
          src={appStore}
          alt="app-store"
          className="w-[140px] max-lg:w-[110px]  max-sm:w-[75px]"
        />
        <p className="text-normal text-black_color text-xl max-md:text-lg max-sm:text-base">
          Join the waitlist and be the first to try.
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex justify-center items-center max-w-[600px] mx-auto  border-[1.7px] border-[#888888] rounded-full mt-8 "
      >
        <input
          placeholder="Enter Your Email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-placeholder px-10 py-3 w-full rounded-l-full bg-transparent max-sm:px-3 max-sm:py-1 z-10 outline-none"
        />
        <button
          type="submit"
          className="text-bold text-white_color bg-[#159A7DA8] px-8 py-3 rounded-r-full cursor-pointer z-10"
        >
          Submit
        </button>
      </form>
      {popup && <Popup onClose={() => setPopup(false)} />}
    </div>
  );
};

export default HeroContent;
