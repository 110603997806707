import React from "react";
import mobile1 from "../../Assets/mobile1.svg";
import mobile2 from "../../Assets/mobile2.svg";
import mobile3 from "../../Assets/mobile3.svg";
import heroShadow from "../../Assets/hero-shadow.svg";

const StepsSection = () => {
  return (
    <div id="work" className="max-w-[1200px] px-[4%] mx-auto py-[5%]">
      <p className="text-medium text-3xl !font-normal max-w-[70%] mx-auto mt-10 max-lg:text-2xl max-md:text-xl max-sm:text-lg leading-relaxed	">
        <span className=" text-green_color font-bold ">Quell</span> is
        engineered to guide you to healthier digital habits through{" "}
        <span className=" text-white_color bg-green_color ">
          {" "}
          three core strategies{" "}
        </span>
      </p>
      <div className="mt-36 max-lg:mt-28 max-md:mt-20 max-sm:mt-16">
        <div className="flex justify-between items-center px-8 max-md:px-2">
          <div>
            <div className="w-[64px] h-[64px] max-md:h-[45px] max-md:w-[45px] max-md:text-xl max-sm:h-[30px] max-sm:w-[30px] max-sm:text-base rounded-full bg-green_color text-white_color flex justify-center items-center text-3xl font-bold">
              1
            </div>
            <h1 className="mt-8 max-md:mt-2 heading text-5xl text-green_color text-left max-lg:text-4xl max-md:text-2xl max-sm:text-lg">
              Break <span className="text-black_color">the Scroll</span>
            </h1>
            <p className="text-normal text-black_color text-2xl text-left mt-5 max-w-[80%] max-lg:text-2xl max-md:text-xl max-sm:text-base max-sm:mt-3">
              Interrupt doom scrolling sessions to break the cycle
            </p>
          </div>
          <div className="max-w-[30%]">
          <img src={mobile1} alt="mobile1" className="mx-auto" />
          <img src={heroShadow} alt="hero-shadow" className="mt-3 w-[80%] mx-auto"/>
          </div>

        </div>
        <div className="flex flex-row-reverse justify-between items-center max-md:gap-5 px-8 max-md:px-2 mt-20">
          <div className="w-[50%] max-md:w-full">
          <div className="w-[64px] h-[64px] max-md:h-[45px] max-md:w-[45px] max-md:text-xl max-sm:h-[30px] max-sm:w-[30px] max-sm:text-base rounded-full bg-green_color text-white_color flex justify-center items-center text-3xl font-bold">
              2
            </div>
            <h1 className="mt-8 max-md:mt-2 heading text-5xl text-green_color text-left max-lg:text-4xl max-md:text-2xl max-sm:text-lg">
              <span className="text-black_color">Mindful</span><br/> Engagement
            </h1>
            <p className="text-normal text-black_color text-2xl text-left mt-5 max-lg:text-2xl max-md:text-xl max-sm:text-base max-sm:mt-3">
              Scientifically backed breathing exercises to alleviate anxiety and
              guide you into a state of post-scroll clarity.
            </p>
          </div>
          <div  className="max-w-[27%]">
          <img src={mobile2} alt="mobile1" className="mx-auto" />
          <img src={heroShadow} alt="hero-shadow" className="mt-3 w-[80%] mx-auto"/>
          </div>
        </div>
        <div className="flex justify-between items-start px-8  max-md:px-2 mt-20">
          <div>
          <div className="w-[64px] h-[64px] max-md:h-[45px] max-md:w-[45px] max-md:text-xl max-sm:h-[30px] max-sm:w-[30px] max-sm:text-base rounded-full bg-green_color text-white_color flex justify-center items-center text-3xl font-bold">
              3
            </div>
            <h1 className="mt-8 max-md:mt-2 heading text-5xl text-green_color text-left max-lg:text-4xl max-md:text-2xl max-sm:text-lg">
            <span className="text-black_color">Screen Time</span><br/> Insights
            </h1>
            <p className="text-normal text-black_color text-3xl text-left mt-5 max-w-[80%] max-lg:text-2xl max-md:text-xl max-sm:text-base max-sm:mt-3">
              Gradually reduce your overall screen time to foster digital
              discipline
            </p>
            
          </div>
          <div className="max-w-[30%]">
          <img src={mobile3} alt="mobile1" className="mx-auto" />
          <img src={heroShadow} alt="hero-shadow" className="mt-3 w-[80%] mx-auto"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsSection;
