import React, { useState } from "react";
import googlePlay from "../../Assets/googlePlay.svg";
import appStore from "../../Assets/appStore.svg";

import "../../firebase.js";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import Popup from "../Popup/Popup.jsx";

const CourseSection = () => {
  const db = getFirestore();
  const [email, setEmail] = useState("");
  const [popup, setPopup] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "email"), { email: email });
    setEmail("")
    setPopup(true)
  };

  return (
    <div className="max-w-[1200px] px-[4%] mx-auto py-[5%]">
      <h1 className="heading text-4xl  text-left max-w-[70%] mt-5 max-lg:text-3xl max-md:text-xl max-sm:text-lg ">
        Set Your Course
      </h1>
      <p className="text-normal !text-black_color text-3xl text-left mt-5 max-lg:text-2xl max-md:text-xl max-sm:text-base max-sm:mt-3">
        Begin your journey to digital well-being today. Let Quell guide you to
        where you truly want to be, far from the endless scroll.
      </p>
      

    </div>
  );
};

export default CourseSection;
