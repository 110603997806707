import React from "react";
import twitter from "../../Assets/twitter.svg";
import linkedin from "../../Assets/linkedin.svg";
import discord from "../../Assets/discord.svg";
const Footer = () => {
  return (
    <div className="bg-green_color py-[10%] hero-image2 flex justify-center items-center">
      <div className="max-w-[1200px] px-[4%] mx-auto">
        <div className="flex gap-14 justify-center">
          <img
            src={twitter}
            alt="twitter"
            className="w-[40px] max-md:w-[30px] max-sm:w-[25px]"
          />
          <a href="https://www.linkedin.com/company/the-quell-app/" target="_blank">
            <img
              src={linkedin}
              alt="linkedin"
              className="w-[40px] max-md:w-[30px] max-sm:w-[25px]"
            />
          </a>
          <a href="https://discord.gg/3mMj7jpg" target="_blank">
            <img
              src={discord}
              alt="discord"
              className="w-[40px] max-md:w-[30px] max-sm:w-[25px] mt-2"
            />
          </a>
        </div>
        <div className="flex gap-14 justify-center mt-10 max-sm:flex-col max-sm:gap-8">
          <a
            href="mailto:hello@thequellapp.com"
            className="text-normal text-lg text-white_color max-sm:text-base"
          >
            Contact
          </a>
          <a href="/terms-and-conditions" className="text-normal text-lg text-white_color max-sm:text-base">
            Terms of Use
          </a>
          <a href="/privacy-policy" className="text-normal text-lg text-white_color max-sm:text-base">
            Privacy Policy
          </a>
          <a className="text-normal text-lg text-white_color max-sm:text-base">
            FAQ
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
